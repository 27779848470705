/// In every file you need this!
import $ from 'jquery';
//attach jquery to dom
window.jQuery = $;
window.$ = $;

import 'owl.carousel';

$(document).ready(function () {

	if ($(".reviews_carousel_section .owl-carousel")[0]) {
		var carousel = $(".reviews_carousel_section .owl-carousel");

		carousel.owlCarousel({
			autoplay: true,
			autoplayTimeout: 5000,
			autoplayHoverPause: true,
			loop: true,
			dots: false,
			nav: false,
			responsive: {
				0: {
					items: 1,
					margin: 0,
				},
				992: {
					items: 2,
					margin: 50,
				}
			}
		});

		$(".reviews_carousel_section .prev_button").on("click", function() {
			$(".reviews_carousel_section .owl-carousel button.owl-prev").trigger("click");
		});

		$(".reviews_carousel_section .next_button").on("click", function() {
			$(".reviews_carousel_section .owl-carousel button.owl-next").trigger("click");
		});
	}

});